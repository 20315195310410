import { ApolloClient, InMemoryCache } from '@apollo/client'

export const healthClient = new ApolloClient({
  uri: 'https://api.thegraph.com/index-node/graphql',
  cache: new InMemoryCache(),
})

export const bnbClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/56564/wombat-exchange-bsc/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const bnbBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/56564/bsc-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const arbitrumBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/56564/arbone-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const ethereumBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/56564/eth-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const polygonBlockClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/wombat-exchange/polygon-chain-block',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const avalancheBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/proxy/56564/avax-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const baseBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/base-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const optimismBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/op-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const scrollBlockClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/scroll-chain-block/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const womTokenClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/wombat-exchange/wombat-exchange-bsc',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const womCirculatingSupplyClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-circulating-supply/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const womAprClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-apr/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const bribeClient = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/corey-wombat/wombat-bribe-revenue',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const arbitrumClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-arbone/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const ethereumClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-eth/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const polygonClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-polygon/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const avalancheClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-avax/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const baseClient = new ApolloClient({
  // NOTE: for Subgraph Studio, we manually set the version on each deployment
  //       (`v0.0.0` for example). That deployed version will be available at a
  //       specific endpoint (`.../wombat-exchange-base/v0.0.0` for example). To
  //       ensure that we use the latest deployed version, we should use the
  //       form `.../[subgraph-name]/version/latest`
  //       (`.../wombat-exchange-base/version/latest` for example)
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-base/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const optimismClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-op/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const scrollClient = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/56564/wombat-exchange-scroll/version/latest',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// TODO: Update the URI
export const stellarClient = new ApolloClient({
  uri: 'https://wombatgraphqlsolana.sotatek.works/graphql',
  // uri: 'https://wombatgraphqlstellar-dev.sotatek.works/graphql',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// TODO: Update the URI
export const stellarBlockClient = new ApolloClient({
  uri: 'https://wombatgraphqlsolana.sotatek.works/graphql',
  // uri: 'https://wombatgraphqlstellar-dev.sotatek.works/graphql',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// TODO: Update the URI when deploy mainnet
export const solanaClient = new ApolloClient({
  uri: 'https://wombatgraphqlstellar.sotatek.works/graphql',
  // uri: 'https://wombatgraphqlsolana-dev.sotatek.works/graphql',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// TODO: Update the URI when deploy mainnet
export const solanaBlockClient = new ApolloClient({
  uri: 'https://wombatgraphqlstellar.sotatek.works/graphql',
  // uri: 'https://wombatgraphqlsolana-dev.sotatek.works/graphql',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
