import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { StyledInternalLink, TYPE } from 'theme'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import LineChart from 'components/LineChart/alt'
import useTheme from 'hooks/useTheme'
import Card from 'components/Card'
import { formatAmount } from 'utils/numbers'
import { PageWrapper, ColorBackground } from 'pages/styled'
import { unixToDate } from 'utils/date'
import { MonoSpace, WorkSans } from 'components/shared'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useVeWomChartData, useVeWomData, useVeWomPieChart, useVeWomTransactions } from 'state/vewom/hooks'
import VeWomTransactionTable from 'components/VeWomTransactionTable'
import { ExternalLink } from '../../theme/components'
import CurrencyLogo, { ProjectLogo } from 'components/CurrencyLogo'
import { GenericImageWrapper } from 'components/Logo'
import { ToggleWrapper, ToggleElementFree } from 'components/Toggle'
import { BNBNetworkInfo, NETWORK_INFOS, SupportedNetwork } from 'constants/networks'
import { generateColors, getEtherscanLink, shortenAddress } from 'utils'
import { networkPrefix } from 'utils/networkPrefix'
import { useProtocolData } from 'state/protocol/hooks'
import { WomSupplyData } from 'data/protocol/overview'
import { BaiJamjureeer } from 'components/shared'
import { DoughnutChartData } from 'components/charts/DoughnutChart'
import { formatTopUser } from 'utils/topUser'
import { useCirculatingSupply, useTotalLockedWom } from 'hooks/useWombatApi'
import useBreakpoints from 'hooks/useBreakpoints'
import { VeVomTransactionSort } from 'components/VeWomTransactionSort'
import { VeWomTransactionType } from 'types'
import { VeWomDoughnutChart } from 'components/VeWomDoughnutChart'
import { VeWomLineChart } from 'components/VeWomLineChart'

const StyledDesktopRow = styled(AutoRow)`
  ${({ theme }) => theme.mediaWidth.upToSmall`display: none`};
`
const VEWOM_ADDRESS = '0x3da62816dd31c56d9cdf22c6771ddb892cb5b0cc'

export const StyledDarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg1};
  opacity: 0.8;
  border: 2px solid ${({ theme }) => theme.bg2};
  border-radius: 13px;
  padding: 25px 25px;
`

export const StyledVeWomDoughnutChartContainer = styled(StyledDarkGreyCard)`
  @media screen and (max-width: 1400px) {
    padding-bottom: 50px;
  }
`

export const WhiteLineChart = styled(LineChart)`
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 0px;
`
const StyledMobileRow = styled(AutoRow)`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`display: flex`};
`

const ContentLayout = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 1em;
  display: flex;

  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
`

const Bottom = styled.div`
  width: 100%;
  border: 1px solid #b7a38d;
  margin: 5px auto;
`

const VomInfoContainer = styled(RowFixed)`
  display: flex;
  align-item: center;
`

enum displayChart {
  lockedWom,
  totalVeWomSupply,
}

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const theme = useTheme()

  const [activeNetwork] = useActiveNetworkVersion()
  const [protocolData] = useProtocolData()
  const [veWomData] = useVeWomData()
  const [veWomPieChartData] = useVeWomPieChart()
  const [veWomChartData] = useVeWomChartData()
  const [transactions] = useVeWomTransactions()
  const [womSupplyData, setWomSupplyData] = useState<WomSupplyData | undefined>()
  const [lockedWomHover, setLockedWomHover] = useState<number | undefined>()
  const [veWomSupplyHover, setVeWomSupplyHover] = useState<number | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()
  const { isMobileLg } = useBreakpoints()
  // filter on txn type
  const [txFilter, setTxFilter] = useState<VeWomTransactionType | undefined>(undefined)

  /**
   * Pie chart related
   */
  const top10HoldersDoughnutChartData = useMemo<DoughnutChartData | undefined>(() => {
    if (!veWomPieChartData) return
    const top10Data = veWomPieChartData.slice(0, 10)
    const formattedTop10Data = formatTopUser(top10Data, veWomData?.veWomData.totalSupply ?? 0)
    const colorGenerator = generateColors()
    return formattedTop10Data.reduce(
      (outputResult, currentData, index) => {
        outputResult.backgroundColors.push(colorGenerator())
        outputResult.dataset.push(currentData.veWomBalance)
        outputResult.labels.push(
          <div className="flex flex-row items-center gap-2" key="1">
            <WorkSans>
              <TYPE.subHeader color="#000" style={{ display: 'flex', flexDirection: 'row' }}>
                #{index + 1}&nbsp;&nbsp;
                {currentData.label && currentData.label != 'Others' ? (
                  <ProjectLogo address={currentData.label} size="16px" />
                ) : (
                  <></>
                )}
                &nbsp;
                {currentData.id !== '' ? (
                  <ExternalLink
                    href={getEtherscanLink(currentData.id, 'address', activeNetwork)}
                    style={{ color: theme.wombatPurple1 }}
                  >
                    {currentData.label ? currentData.label : shortenAddress(currentData.id, 2, 4, activeNetwork.id)}
                  </ExternalLink>
                ) : (
                  <>{currentData.label}</>
                )}
                &nbsp;&nbsp;
                {veWomSupplyHover && ((currentData.veWomBalance / veWomSupplyHover) * 100).toFixed(2)}%
              </TYPE.subHeader>
              <TYPE.subHeader fontSize="15px" fontWeight={600} color={theme.wombatBrown1}>{`${formatAmount(
                currentData.veWomBalance,
                2
              )} veWom`}</TYPE.subHeader>
            </WorkSans>
          </div>
        )
        return outputResult
      },
      { backgroundColors: [], dataset: [], labels: [] } as DoughnutChartData
    )
  }, [
    activeNetwork,
    theme.wombatBrown1,
    theme.wombatPurple1,
    veWomData?.veWomData.totalSupply,
    veWomPieChartData,
    veWomSupplyHover,
  ])

  const circulatingSupply = useCirculatingSupply() ?? 0
  const totalLockedWom = useTotalLockedWom() ?? 0
  const totalStakedToday = Object.values(protocolData?.stakedWomData || {}).reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue?.stakedToday)
  }, 0)

  useEffect(() => {
    setLockedWomHover(undefined)
    setVeWomSupplyHover(undefined)
  }, [activeNetwork])

  useEffect(() => {
    if (womSupplyData === undefined && protocolData) {
      setWomSupplyData(protocolData.womSupplyData)
    }
  }, [protocolData, womSupplyData])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (lockedWomHover === undefined && veWomData) {
      setLockedWomHover(veWomData.veWomData.totalLockedWom)
    }
  }, [veWomData, lockedWomHover])

  useEffect(() => {
    if (veWomSupplyHover === undefined && veWomData) {
      setVeWomSupplyHover(veWomData.veWomData.totalSupply)
    }
  }, [veWomData, veWomSupplyHover])

  const cumulativeSum = (
    (sum) => (value: any, value2: any) =>
      (sum = sum + value + value2)
  )(0)
  const formattedLockedWomData = useMemo(() => {
    if (veWomChartData) {
      return veWomChartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: cumulativeSum(day.dayLockedWom, day.dayUnlockedWom),
        }
      })
    } else {
      return []
    }
  }, [cumulativeSum, veWomChartData])

  const formattedVeWomSupplyData = useMemo(() => {
    if (veWomChartData) {
      return veWomChartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.dayTotalSupply,
        }
      })
    } else {
      return []
    }
  }, [veWomChartData])

  const [chartWindow, setChartWindow] = useState(displayChart.lockedWom)

  return (
    <PageWrapper>
      <ColorBackground />
      <AutoColumn gap="16px">
        <AutoColumn gap="24px">
          <StyledDesktopRow justify="space-between">
            <RowFixed>
              <CurrencyLogo isVewom size="60px" style={{ borderRadius: '0px', boxShadow: 'none' }} />
              <TYPE.label fontWeight={700} fontSize="40px" ml={'20px'} mr={'10px'}>
                {' veWOM'}
              </TYPE.label>
              {activeNetwork === BNBNetworkInfo ? null : (
                <GenericImageWrapper src={activeNetwork.imageURL} style={{ marginLeft: '8px' }} size={'26px'} />
              )}
            </RowFixed>
          </StyledDesktopRow>
          <StyledMobileRow justify="space-between">
            <StyledInternalLink to={networkPrefix(activeNetwork)}>
              <TYPE.largeHeader fontSize={'12px'} color={theme.black}>{`< BACK`}</TYPE.largeHeader>
            </StyledInternalLink>
          </StyledMobileRow>
          <StyledMobileRow justify="space-between">
            <RowFixed>
              <CurrencyLogo
                address={VEWOM_ADDRESS}
                style={{ marginRight: '8px', borderRadius: '0px', boxShadow: 'none' }}
              />
              <AutoColumn>
                <TYPE.label fontWeight={700} fontSize="18px">
                  {'veWOM'}
                </TYPE.label>
                <TYPE.subHeader color={theme.bg4}>{'veWOM'}</TYPE.subHeader>
              </AutoColumn>
            </RowFixed>
          </StyledMobileRow>
        </AutoColumn>
        <ContentLayout>
          <StyledDarkGreyCard minHeight="640px">
            <AutoColumn gap="10px">
              <RowBetween>
                <BaiJamjureeer>
                  <TYPE.main fontWeight={400} color={'black'}>
                    WOM Circulating Supply:
                  </TYPE.main>
                </BaiJamjureeer>
                <BaiJamjureeer>
                  <TYPE.label fontSize="15px" color={'#585148'}>{`${formatAmount(
                    circulatingSupply,
                    2
                  )} WOM`}</TYPE.label>
                </BaiJamjureeer>
              </RowBetween>
              <RowBetween>
                <BaiJamjureeer>
                  <TYPE.main fontWeight={400} color={'black'}>
                    WOM Total Locked:
                  </TYPE.main>
                </BaiJamjureeer>
                <BaiJamjureeer>
                  <TYPE.label fontSize="15px" color={'#585148'}>{`${formatAmount(
                    totalStakedToday,
                    2
                  )} WOM`}</TYPE.label>
                </BaiJamjureeer>
              </RowBetween>
              {Object.entries(protocolData?.stakedWomData || []).map(([key, value]) => {
                const stakedPercent =
                  circulatingSupply > 0 && value
                    ? (Number(value.stakedToday) * 100) / (Number(circulatingSupply) + Number(totalLockedWom))
                    : 0
                return (
                  <React.Fragment key={key}>
                    {value && value.stakedToday > 0 && (
                      <RowBetween>
                        <BaiJamjureeer>
                          <TYPE.main fontWeight={400} color={'#585148'} marginLeft={'20px'}>
                            {NETWORK_INFOS[(Number(key) ? Number(key) : key) as SupportedNetwork].name}
                          </TYPE.main>
                        </BaiJamjureeer>
                        <BaiJamjureeer>
                          <TYPE.label fontSize="15px" color={'#585148'}>{`${formatAmount(value.stakedToday, 2)} WOM (${
                            stakedPercent > 0 ? stakedPercent.toFixed(2) : '-'
                          }%)`}</TYPE.label>
                        </BaiJamjureeer>
                      </RowBetween>
                    )}
                  </React.Fragment>
                )
              })}
              <Bottom />
              <RowBetween align="flex-start">
                <AutoColumn>
                  <RowFixed>
                    <TYPE.label fontSize={isMobileLg ? '16px' : '32px'} color={'#B672D6'}>
                      <BaiJamjureeer>
                        {chartWindow === displayChart.lockedWom
                          ? `${formatAmount(lockedWomHover, 2)} WOM`
                          : `${formatAmount(veWomSupplyHover, 2)} veWOM`}
                      </BaiJamjureeer>
                    </TYPE.label>
                  </RowFixed>
                  <TYPE.main fontSize="12px">{rightLabel ? <MonoSpace>{rightLabel} (UTC)</MonoSpace> : null}</TYPE.main>
                </AutoColumn>
                <RowFixed>
                  <VomInfoContainer>
                    <ToggleWrapper>
                      <ToggleElementFree
                        isActive={chartWindow === displayChart.lockedWom}
                        fontSize="12px"
                        onClick={() =>
                          chartWindow === displayChart.lockedWom
                            ? setChartWindow(displayChart.totalVeWomSupply)
                            : setChartWindow(displayChart.lockedWom)
                        }
                      >
                        Locked WOM
                      </ToggleElementFree>
                      <ToggleElementFree
                        isActive={chartWindow === displayChart.totalVeWomSupply}
                        fontSize="12px"
                        onClick={() =>
                          chartWindow === displayChart.totalVeWomSupply
                            ? setChartWindow(displayChart.lockedWom)
                            : setChartWindow(displayChart.totalVeWomSupply)
                        }
                      >
                        veWOM Supply
                      </ToggleElementFree>
                    </ToggleWrapper>
                  </VomInfoContainer>
                </RowFixed>
              </RowBetween>
              <VeWomLineChart
                data={chartWindow === displayChart.lockedWom ? formattedLockedWomData : formattedVeWomSupplyData}
                value={chartWindow === displayChart.lockedWom ? lockedWomHover : veWomSupplyHover}
                label={rightLabel}
                setValue={chartWindow === displayChart.lockedWom ? setLockedWomHover : setVeWomSupplyHover}
                setLabel={setRightLabel}
              />
            </AutoColumn>
          </StyledDarkGreyCard>
          <StyledVeWomDoughnutChartContainer>
            <BaiJamjureeer>
              <TYPE.main fontWeight={400} fontSize={'25px'} color={'black'}>
                Top Holders
                {top10HoldersDoughnutChartData && (
                  <VeWomDoughnutChart
                    top10HoldersDoughnutChartData={top10HoldersDoughnutChartData}
                    veWomSupplyHover={veWomSupplyHover}
                  />
                )}
              </TYPE.main>
            </BaiJamjureeer>
          </StyledVeWomDoughnutChartContainer>
        </ContentLayout>
        <BaiJamjureeer>
          <RowFixed>
            <TYPE.main fontSize={'25px'} marginRight={20}>
              Transactions
            </TYPE.main>
            <VeVomTransactionSort txFilter={txFilter} setTxFilter={setTxFilter}></VeVomTransactionSort>
          </RowFixed>
        </BaiJamjureeer>
        {transactions ? (
          <VeWomTransactionTable transactions={transactions} color={activeNetwork.primaryColor} txFilter={txFilter} />
        ) : null}
      </AutoColumn>
    </PageWrapper>
  )
}
