import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { NetworkInfo, SupportedNetwork } from 'constants/networks'
import gql from 'graphql-tag'
import { Transaction, TransactionType } from 'types'

const GLOBAL_TRANSACTIONS = gql`
  {
    swaps(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      fromToken {
        id
        symbol
      }
      toToken {
        id
        symbol
      }
      sentFrom
      fromAmount
      toAmount
      amount: amountUSD
    }
    deposits(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      token {
        id
        symbol
      }
      sentFrom
      amount
      amountUSD
    }
    withdraws(first: 500, orderBy: timestamp, orderDirection: desc, subgraphError: allow) {
      timestamp
      id
      token {
        id
        symbol
      }
      sentFrom
      amount
      amountUSD
    }
  }
`

const REFUND_TRANSACTIONS = gql`
  {
    stateForSwaps(first: 500, where: { status_not: 3 }, orderBy: timestamp, orderDirection: desc) {
      timestamp
      fromToken
      toToken
      fromAmount
      toAmount
      user
      events
      detail
    }
    stateForWithdraws(first: 500, where: { status_not: 3 }, orderBy: timestamp, orderDirection: desc) {
      timestamp
      fromToken
      toToken
      fromAmount
      toAmount
      user
      events
      detail
    }
  }
`

const TOKEN_SYMBOL = gql`
  {
    tokens {
      id
      symbol
    }
  }
`

export type Swap = {
  timestamp: string
  id: string
  fromToken: {
    id: string
    symbol: string
  }
  toToken: {
    id: string
    symbol: string
  }
  sentFrom: string
  fromAmount: string
  toAmount: string
  amount: string
}

export type Deposit = {
  timestamp: string
  id: string
  token: {
    id: string
    symbol: string
  }
  sentFrom: string
  amount: string
  amountUSD: string
}

export type Withdraw = {
  timestamp: string
  id: string
  token: {
    id: string
    symbol: string
  }
  sentFrom: string
  amount: string
  amountUSD: string
}

export type Block = {
  id: number
  hash: string
  number: string
  createdAt: string
  timestamp: string
  parentHash: string
}

export type TransactionDetail = {
  to: string
  from: string
  hash: string
}

export type Event = {
  block: Block
  params: {
    user: string
    nonce: string
    status: string
  }
  address: string
  eventName: string
  transaction: TransactionDetail
}

export type Detail = {
  block: Block
  params: {
    to: string
    user: string
    nonce: string
    toToken: string
    deadline: string
    fromToken: string
    fromAmount: string
    minimumToAmount: string
  }
  address: string
  eventName: string
  transaction: TransactionDetail
}

export type StateForSwap = {
  timestamp: string
  fromToken: string
  toToken: string
  fromAmount: string
  toAmount: string
  user: string
  events: Event[]
  detail: Detail
}

export type TokenSymbol = {
  id: string
  symbol: string
}

export type StateForWithdraw = StateForSwap

export interface TokenSymbolData {
  [key: string]: string
}

interface TransactionResults {
  swaps: Swap[]
  deposits: Deposit[]
  withdraws: Withdraw[]
}

interface TransactionRefundResults {
  stateForSwaps: StateForSwap[]
  stateForWithdraws: StateForWithdraw[]
}

interface TokenSymbolResults {
  tokens: TokenSymbol[]
}

export async function fetchTopTransactions(
  client: ApolloClient<NormalizedCacheObject>,
  activeNetwork?: NetworkInfo
): Promise<Transaction[] | undefined> {
  try {
    const { data, error, loading } = await client.query<TransactionResults>({
      query: GLOBAL_TRANSACTIONS,
      fetchPolicy: 'cache-first',
    })

    let refunds: Transaction[] = []

    if (error || loading || !data) {
      return undefined
    }

    if (activeNetwork && activeNetwork.id === SupportedNetwork.STELLAR) {
      const {
        data: refundData,
        error,
        loading,
      } = await client.query<TransactionRefundResults>({
        query: REFUND_TRANSACTIONS,
      })

      if (error || loading || !refundData) {
        return undefined
      }

      const {
        data: tokenData,
        error: tokenError,
        loading: tokenLoading,
      } = await client.query<TokenSymbolResults>({
        query: TOKEN_SYMBOL,
      })

      if (tokenError || tokenLoading || !tokenData) {
        return undefined
      }

      const formattedToken = tokenData.tokens.reduce<TokenSymbolData>((acc: TokenSymbolData, token: TokenSymbol) => {
        acc[token.id] = token.symbol
        return acc
      }, {})

      const formattedRefundSwaps = refundData.stateForSwaps.map((m: StateForSwap) => {
        const swapEntry = {
          hash: m.events[m.events.length - 1].transaction.hash,
          type: TransactionType.REFUND,
          refundType: TransactionType.SWAP,
          timestamp: m.timestamp,
          sentFrom: m.user,
          token0Symbol: formattedToken[m.fromToken],
          token1Symbol: formattedToken[m.toToken],
          token0Address: m.fromToken,
          token1Address: m.toToken,
          // TODO: check against the actual amount
          amountUSD: parseFloat(m.fromAmount),
          amountToken0: parseFloat(m.fromAmount),
          amountToken1: parseFloat(m.toAmount),
        }
        return swapEntry
      }, [])
      const formattedRefundWithdraws = refundData.stateForWithdraws.map((m: StateForWithdraw) => {
        const withdrawEntry = {
          type: TransactionType.REFUND,
          refundType: TransactionType.BURN,
          hash: m.events[m.events.length - 1].transaction.hash,
          timestamp: m.timestamp,
          sentFrom: m.user,
          token0Symbol: formattedToken[m.fromToken],
          token1Symbol: formattedToken[m.toToken],
          token0Address: m.fromToken,
          token1Address: m.toToken,
          // TODO: check against the actual amount
          amountUSD: parseFloat(m.fromAmount),
          amountToken0: parseFloat(m.fromAmount),
          amountToken1: parseFloat(m.toAmount),
        }
        return withdrawEntry
      }, [])
      refunds = [...formattedRefundSwaps, ...formattedRefundWithdraws]
    }

    const formattedSwaps = data.swaps.map((m: Swap) => {
      const swapEntry = {
        hash: m.id,
        type: TransactionType.SWAP,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.fromToken.symbol,
        token1Symbol: m.toToken.symbol,
        token0Address: m.fromToken.id,
        token1Address: m.toToken.id,
        amountUSD: parseFloat(m.amount),
        amountToken0: parseFloat(m.fromAmount),
        amountToken1: parseFloat(m.toAmount),
      }
      return swapEntry
    }, [])
    const formattedDeposits = data.deposits.map((m: Deposit) => {
      const depositEntry = {
        type: TransactionType.MINT,
        hash: m.id,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.token.symbol,
        token1Symbol: m.token.symbol,
        token0Address: m.token.id,
        token1Address: m.token.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount),
        amountToken1: parseFloat(m.amount),
      }
      return depositEntry
    }, [])
    const formattedWithdraws = data.withdraws.map((m: Withdraw) => {
      const withdrawEntry = {
        type: TransactionType.BURN,
        hash: m.id,
        timestamp: m.timestamp,
        sentFrom: m.sentFrom,
        token0Symbol: m.token.symbol,
        token1Symbol: m.token.symbol,
        token0Address: m.token.id,
        token1Address: m.token.id,
        amountUSD: parseFloat(m.amountUSD),
        amountToken0: parseFloat(m.amount),
        amountToken1: parseFloat(m.amount),
      }
      return withdrawEntry
    }, [])
    const accum = [...formattedSwaps, ...formattedDeposits, ...formattedWithdraws, ...refunds]
    return accum
  } catch {
    return undefined
  }
}
