import { healthClient } from './../../apollo/client'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useActiveNetworkVersion } from '../../state/application/hooks'
import { SupportedNetwork } from '../../constants/networks'

export const SUBGRAPH_HEALTH = gql`
  query health($name: Bytes) {
    indexingStatusForCurrentVersion(subgraphName: $name, subgraphError: allow) {
      synced
      health
      chains {
        chainHeadBlock {
          number
        }
        latestBlock {
          number
        }
      }
    }
  }
`

interface HealthResponse {
  indexingStatusForCurrentVersion: {
    chains: {
      chainHeadBlock: {
        number: string
      }
      latestBlock: {
        number: string
      }
    }[]
    synced: boolean
  }
}

/**
 * Fetch top addresses by volume
 */
export function useFetchedSubgraphStatus(): {
  available: boolean | null
  syncedBlock: number | undefined
  headBlock: number | undefined
} {
  const [network] = useActiveNetworkVersion()
  let subgraphName = ''
  switch (network.id) {
    case SupportedNetwork.BNB:
      subgraphName = 'wombat-exchange/wombat-exchange-bsc'
      break
    case SupportedNetwork.ARBITRUM:
      subgraphName = 'wombat-exchange/wombat-exchange-arbone'
      break
    case SupportedNetwork.ETHEREUM:
      subgraphName = 'wombat-exchange/wombat-exchange-eth'
      break
    case SupportedNetwork.POLYGON:
      subgraphName = 'wombat-exchange/wombat-exchange-polygon'
      break
    case SupportedNetwork.AVALANCHE:
      subgraphName = 'wombat-exchange/wombat-exchange-avax'
      break
    case SupportedNetwork.BASE:
      subgraphName = 'wombat-exchange/wombat-exchange-base'
      break
    case SupportedNetwork.OPTIMISM:
      subgraphName = 'wombat-exchange/wombat-exchange-op'
      break
    case SupportedNetwork.SCROLL:
      subgraphName = 'wombat-exchange/wombat-exchange-scroll'
      break
    case SupportedNetwork.STELLAR:
      // TODO: add stellar subgraph name to check for health
      break
    case SupportedNetwork.SOLANA:
      // TODO: add solana subgraph name to check for health
      break
    case SupportedNetwork.SCROLL:
      subgraphName = 'wombat-exchange/wombat-exchange-scroll'
      break
    default:
      const exhaustiveCheck: never = network.id
      const error = {
        method: 'useFetchSubgraphStatus',
        values: [exhaustiveCheck],
        message: 'unhandled network ID',
      }
      console.error(error)
      throw new Error(JSON.stringify(error))
  }

  const { loading, error, data } = useQuery<HealthResponse>(SUBGRAPH_HEALTH, {
    client: healthClient,
    fetchPolicy: 'network-only',
    variables: {
      name: subgraphName,
    },
  })

  const parsed = data?.indexingStatusForCurrentVersion

  if (loading) {
    return {
      available: null,
      syncedBlock: undefined,
      headBlock: undefined,
    }
  }

  if ((!loading && !parsed) || error) {
    return {
      available: false,
      syncedBlock: undefined,
      headBlock: undefined,
    }
  }

  const syncedBlock = parsed?.chains[0].latestBlock.number
  const headBlock = parsed?.chains[0].chainHeadBlock.number

  return {
    available: true,
    syncedBlock: syncedBlock ? parseFloat(syncedBlock) : undefined,
    headBlock: headBlock ? parseFloat(headBlock) : undefined,
  }
}
