import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { NetworkInfo, SupportedNetwork } from 'constants/networks'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import gql from 'graphql-tag'
import { AssetChartEntry } from 'state/assets/reducer'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)
const ONE_DAY_UNIX = 24 * 60 * 60

const ASSET_CHART = gql`
  query assetDayDatas($startTime: BigInt!, $skip: Int!, $address: Bytes!) {
    assetDayDatas(
      first: 1000
      skip: $skip
      where: { asset: $address, dayID_gt: $startTime, asset_not: "0x3f56e0c36d275367b8c502090edf38289b3dea0d" }
      orderBy: dayID
      orderDirection: asc
      subgraphError: allow
    ) {
      date: dayID
      feeUSD: dailyCollectedFeeUSD
      volumeUSD: dailyTradeVolumeUSD
      totalValueLockedUSD: dailyLiabilityUSD
    }
  }
`

const ASSET_CHART_STELLAR_SOLANA = gql`
  query assetDayDatas($startTime: BigInt!, $skip: Int!, $address: String!) {
    assetDayDatas(
      first: 1000
      skip: $skip
      where: { asset: $address, dayID_gt: $startTime, asset_not: "0x3f56e0c36d275367b8c502090edf38289b3dea0d" }
      orderBy: dayID
      orderDirection: asc
      subgraphError: allow
    ) {
      date: dayID
      feeUSD: dailyCollectedFeeUSD
      volumeUSD: dailyTradeVolumeUSD
      totalValueLockedUSD: dailyLiabilityUSD
    }
  }
`

interface Data {
  date: string
  feeUSD: string
  volumeUSD: string
  totalValueLockedUSD: string
}

interface ChartResults {
  assetDayDatas: Data[]
}

export async function fetchAssetChartData(
  address: string,
  client: ApolloClient<NormalizedCacheObject>,
  decimals = '18',
  activeNetwork?: NetworkInfo
) {
  let data: Data[] = []
  // const decimals = '18'
  const startTimestamp = 1647820800 // 21 March 2022 00:00:00
  const endTimestamp = dayjs.utc().unix()

  let error = false
  let skip = 0
  let allFound = false
  try {
    while (!allFound) {
      const {
        data: chartResData,
        error,
        loading,
      } = await client.query<ChartResults>({
        query:
          activeNetwork &&
          (activeNetwork.id === SupportedNetwork.STELLAR || activeNetwork.id === SupportedNetwork.SOLANA)
            ? ASSET_CHART_STELLAR_SOLANA
            : ASSET_CHART,
        variables: {
          address: address,
          startTime: parseInt((startTimestamp / ONE_DAY_UNIX).toFixed(0)),
          skip,
        },
        fetchPolicy: 'no-cache',
      })
      if (!loading) {
        skip += 1000
        if (chartResData.assetDayDatas.length < 1000 || error) {
          allFound = true
        }
        if (chartResData) {
          data = data.concat(chartResData.assetDayDatas)
        }
      }
    }
  } catch {
    error = true
  }
  if (data && decimals) {
    const formattedExisting = data.reduce((accum: { [date: number]: AssetChartEntry }, dayData) => {
      const roundedDate = parseInt(dayData.date)
      const dateTimestamp = roundedDate * ONE_DAY_UNIX
      accum[roundedDate] = {
        date: dateTimestamp,
        volumeUSD: parseFloat(dayData.volumeUSD) / 2,
        feeUSD: parseFloat(dayData.feeUSD),
        totalValueLockedUSD: +dayData.totalValueLockedUSD,
      }
      return accum
    }, {})

    const firstEntry = formattedExisting[parseInt(Object.keys(formattedExisting)[0])]

    // fill in empty days ( there will be no day datas if no trades made that day )
    let timestamp = firstEntry?.date ?? startTimestamp
    let latestTvl = firstEntry?.totalValueLockedUSD ?? 0
    while (timestamp < endTimestamp - ONE_DAY_UNIX) {
      const nextDay = timestamp + ONE_DAY_UNIX
      const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0))
      if (!Object.keys(formattedExisting).includes(currentDayIndex.toString())) {
        formattedExisting[currentDayIndex] = {
          date: nextDay,
          volumeUSD: 0,
          totalValueLockedUSD: latestTvl,
          feeUSD: 0,
        }
      } else {
        latestTvl = formattedExisting[currentDayIndex].totalValueLockedUSD
      }
      timestamp = nextDay
    }

    const dateMap = Object.keys(formattedExisting).map((key) => {
      return formattedExisting[parseInt(key)]
    })

    return {
      data: dateMap,
      error: false,
    }
  } else {
    return {
      data: undefined,
      error,
    }
  }
}
