import { useEffect, useState } from 'react'
import { CoingeckoPlatformId } from '../constants/networks'
import { IncubationToken } from 'utils'

export enum TokenSymbol {
  WOM = 'WOM',
  BUSD = 'BUSD',
  DAI = 'DAI',
  TUSD = 'TUSD',
  USDC = 'USDC',
  vUSDC = 'vUSDC',
  USDT = 'USDT',
}

const ALL_TOKEN_IDS: { [tokenSymbol: string]: string } = {
  [TokenSymbol.WOM]: '',
  [TokenSymbol.BUSD]: 'binance-usd',
  [TokenSymbol.DAI]: 'dai',
  [TokenSymbol.TUSD]: 'true-usd',
  [TokenSymbol.USDC]: 'usd-coin',
  [TokenSymbol.vUSDC]: 'venus-usdc',
  [TokenSymbol.USDT]: 'tether',
}

export interface PriceResponse {
  [tokenAddress: string]: {
    usd: number
    usd_24h_change: number
    last_updated_at: string
  }
}

// export async function fetchCurrentTokenPrice(chainSlug: string, contractAddress: string): Promise<number> {
//   const id = `${chainSlug}:${contractAddress}`
//   const response = await fetch(`https://coins.llama.fi/prices/current/${id}?searchWidth=4h`)
//   if (response.ok) {
//     const json = await response.json()
//     return json['coins'][id]['price']
//   } else {
//     return 0
//   }
// }

interface CoinGeckoReponse {
  [tokenSymbol: string]: {
    usd: number
  }
}

export async function fetchCurrentTokenPrice(tokens: IncubationToken[]): Promise<CoinGeckoReponse | undefined> {
  const coinGeckoIds = tokens.map((t) => t.coinGeckoId)
  try {
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${encodeURIComponent(
        coinGeckoIds.join(',')
      )}&vs_currencies=usd&include_market_cap=true&include_24hr_change=true`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          x_cg_demo_api_key: process.env.NEXT_PUBLIC_COINGECKO_API_KEY ?? '',
        },
      }
    )
    if (response.ok) {
      const json = (await response.json()) as CoinGeckoReponse
      return json
    } else {
      return undefined
    }
  } catch (error) {
    return undefined
  }
}

export function useTokenPrice(
  assetPlatformId: CoingeckoPlatformId,
  contractAddress?: string | undefined
): { currentPrice: number | undefined; percentChange24h: number | undefined; lastUpdate: string | undefined } {
  const [price, setPrice] = useState<number | undefined>(undefined)
  const [percentChange, setPercentChange] = useState<number | undefined>(undefined)
  const [lastUpdate, setLastUpdate] = useState<string | undefined>(undefined)
  useEffect(() => {
    async function fetchTokenPrice() {
      if (contractAddress !== undefined && contractAddress !== '') {
        try {
          const url = `https://api.coingecko.com/api/v3/simple/token_price/${assetPlatformId}?contract_addresses=${contractAddress}&vs_currencies=usd&include_24hr_change=true&include_last_updated_at=true`
          const response = await fetch(url)
          const json = (await response.json()) as PriceResponse
          if (!json[contractAddress]) {
            return
          }
          const { usd: currentPrice, usd_24h_change: change24H, last_updated_at: lastUpdate } = json[contractAddress]
          setPrice(currentPrice)
          setPercentChange(change24H)
          setLastUpdate(lastUpdate)
        } catch (error) {
          console.error('Error fetching token price', error)
        }
      }
    }
    fetchTokenPrice()
  }, [contractAddress])

  return { currentPrice: price, percentChange24h: percentChange, lastUpdate: lastUpdate }
}
