import { currentTimestamp } from '../../utils/index'
import {
  updateAssetData,
  addAssetKeys,
  addPoolAddresses,
  updateChartData,
  updatePriceData,
  updateTransactions,
} from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { PriceChartEntry, Transaction } from 'types'
import { SupportedNetwork } from 'constants/networks'

export type AssetData = {
  // token is in some pool on uniswap
  exists: boolean

  // basic token info
  name: string
  pool: string
  symbol: string
  address: string
  token: string

  // tvl
  tvlUSD: number
  tvlUSDChange: number

  // volume
  volumeUSD: number
  volume24HUSD: number
  volume7DUSD: number
  volumeUSDChange: number

  // fee
  fee24HUSD: number
  feeUSDChange: number

  // apr
  baseApr: number
  medianBoostedApr: number
  bonusApr: number
  totalMedianApr: number
}

export interface AssetChartEntry {
  date: number
  volumeUSD: number
  totalValueLockedUSD: number
  feeUSD: number
}

export interface AssetsState {
  // analytics data from
  byAddress: {
    [networkId in SupportedNetwork]: {
      [address: string]: {
        data: AssetData | undefined
        poolAddresses: string[] | undefined
        chartData: AssetChartEntry[] | undefined
        priceData: {
          oldestFetchedTimestamp?: number | undefined
          [secondsInterval: number]: PriceChartEntry[] | undefined
        }
        transactions: Transaction[] | undefined
        lastUpdated: number | undefined
      }
    }
  }
}

export const initialState: AssetsState = {
  byAddress: {
    [SupportedNetwork.BNB]: {},
    [SupportedNetwork.ARBITRUM]: {},
    [SupportedNetwork.ETHEREUM]: {},
    [SupportedNetwork.POLYGON]: {},
    [SupportedNetwork.AVALANCHE]: {},
    [SupportedNetwork.BASE]: {},
    [SupportedNetwork.OPTIMISM]: {},
    [SupportedNetwork.SCROLL]: {},
    [SupportedNetwork.STELLAR]: {},
    [SupportedNetwork.SOLANA]: {},
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateAssetData, (state, { payload: { assets, networkId } }) => {
      assets.map(
        (assetData) =>
          (state.byAddress[networkId][assetData.address] = {
            ...state.byAddress[networkId][assetData.address],
            data: assetData,
            lastUpdated: currentTimestamp(),
          })
      )
    }) // add address to byAddress keys if not included yet
    .addCase(addAssetKeys, (state, { payload: { assetAddresses, networkId } }) => {
      assetAddresses.map((address) => {
        if (!state.byAddress[networkId][address]) {
          state.byAddress[networkId][address] = {
            poolAddresses: undefined,
            data: undefined,
            chartData: undefined,
            priceData: {},
            transactions: undefined,
            lastUpdated: undefined,
          }
        }
      })
    })
    // add list of pools the asset is included in
    .addCase(addPoolAddresses, (state, { payload: { assetAddress, poolAddresses, networkId } }) => {
      state.byAddress[networkId][assetAddress] = { ...state.byAddress[networkId][assetAddress], poolAddresses }
    })
    // add list of pools the asset is included in
    .addCase(updateChartData, (state, { payload: { assetAddress, chartData, networkId } }) => {
      state.byAddress[networkId][assetAddress] = { ...state.byAddress[networkId][assetAddress], chartData }
    })
    // add list of pools the asset is included in
    .addCase(updateTransactions, (state, { payload: { assetAddress, transactions, networkId } }) => {
      state.byAddress[networkId][assetAddress] = { ...state.byAddress[networkId][assetAddress], transactions }
    })
    // update historical price volume based on interval size
    .addCase(
      updatePriceData,
      (state, { payload: { assetAddress, secondsInterval, priceData, oldestFetchedTimestamp, networkId } }) => {
        state.byAddress[networkId][assetAddress] = {
          ...state.byAddress[networkId][assetAddress],
          priceData: {
            ...state.byAddress[networkId][assetAddress].priceData,
            [secondsInterval]: priceData,
            oldestFetchedTimestamp,
          },
        }
      }
    )
)
