import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import AssetPage from './AssetPage'
import { isAddress } from 'ethers/lib/utils'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { SupportedNetwork } from 'constants/networks'

export function RedirectInvalidAsset(props: RouteComponentProps<{ address: string }>) {
  const {
    match: {
      params: { address },
    },
  } = props
  const [activeNetwork] = useActiveNetworkVersion()

  // TODO: add check for stellar and solana
  if (
    activeNetwork.id !== SupportedNetwork.STELLAR &&
    activeNetwork.id !== SupportedNetwork.SOLANA &&
    !isAddress(address)
  ) {
    return <Redirect to={`/`} />
  }
  return <AssetPage {...props} />
}
