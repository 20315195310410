import { useState, useEffect } from 'react'

export function useCirculatingSupply(): number | undefined {
  const [circulatingSupply, setCirculatingSupply] = useState<number | undefined>(undefined)

  useEffect(() => {
    async function fetchCirculatingSupply() {
      try {
        // TODO: update url when deploy mainnet
        const url = 'https://api-wombat.sotatek.works/api/circulating-supply'
        // const url = 'https://api.wombat.exchange/api/circulating-supply'
        const response = await fetch(url)
        const res = await response.text()
        if (!res) {
          return
        }
        setCirculatingSupply(Number(res))
      } catch (error) {
        console.error('Error fetching circulating supply:', error)
      }
    }
    fetchCirculatingSupply()
  }, [])

  return circulatingSupply
}

export function useTotalLockedWom(): number | undefined {
  const [totalLockedWom, setTotalLockedWom] = useState<number | undefined>(undefined)

  useEffect(() => {
    async function fetchTotalLockedWom() {
      try {
        // TODO: update url when deploy mainnet
        const url = 'https://api-wombat.sotatek.works/api/circulating-supply/breakdown'
        // const url = 'https://api.wombat.exchange/api/circulating-supply/breakdown'
        const response = await fetch(url)
        const res = await response.json()
        const breakdown = Object.values(res['lockedSupply']['breakdown'])
        const totalLocked = breakdown.reduce((acc, curr: any) => (acc = acc + curr['veWom']), 0)
        if (!res) {
          return
        }
        setTotalLockedWom(Number(totalLocked))
      } catch (error) {
        console.error('Error fetching total locked WOM:', error)
      }
    }
    fetchTotalLockedWom()
  }, [])

  return totalLockedWom
}
