import { BigNumber } from '@ethersproject/bignumber'
import { Percent, Token } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

import { vevomSvg, assetsSvg, overviewSvg } from '../components/FooterImage/index'
import { SupportedNetwork } from './networks'

import USDCLogo from '../assets/images/token-icon/usdc.svg'
import ZUSDLogo from '../assets/images/token-icon/zusd.svg'
import USDTLogo from '../assets/images/token-icon/usdt.svg'
import WBTCLogo from '../assets/images/token-icon/wbtc.svg'
import SOLLogo from '../assets/images/chains/solana-sol-logo.svg'

export const FACTORY_ADDRESS = '0xFeabCc62240297F1e4b238937D68e7516f0918D7'

export const ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1)

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

export const WOM_ADDRESS = '0xad6742a35fb341a9cc6ad674738dd8da98b94fb1'

export const WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'

export const WETH_ADDRESSES = [WETH_ADDRESS, '0x82af49447d8a07e3bd95bd0d56f35241523fbab1']

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

export const NetworkContextName = 'NETWORK'

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
]

interface RouteInfo {
  name: string
  pathName: string
  icon: ({ fill }: { fill: string }) => JSX.Element
}

export const ROUTE_INFO: RouteInfo[] = [
  { name: 'OVERVIEW', pathName: 'overview', icon: overviewSvg },
  { name: 'ASSETS', pathName: 'assets', icon: assetsSvg },
  { name: 'VEWOM', pathName: 'veWom', icon: vevomSvg },
]

/** @todo split chain */
export const FORMATTED_TOKEN_SYMBOLS: {
  [tokenAddress in string]: string
} = {
  '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 'USDC.e',
}

export const LOWERCASE_HIDED_ASSET_ADDRESS: string[] = [
  // bsc weth
  '0xb268c3181921747379271B9BFfCE8B16311656e3',
  // old smart hay pool
  '0xa393D61fE1532257B69b753aF7d1EfB0e22f1A6E',
  // TUSD
  '0x3c8e744f6c4ed2c9d82e33d69ddcc5961aa05367',
  // BUSD
  '0xa649be04619a8f3b3475498e1ac15c90c9661c1a',
].map((address) => address.toLowerCase())

// TODO: use image from github
const tokenList: Record<string, string> = {
  // NOTE: Stellar token
  cdcjnppsufmhqgx2xbp2he44fiimbczvupy5mdapjrdiuhnj2a3hzwbk: USDCLogo,
  cd5ypsqvdyslylswaptz23lwxum6q35fkuloyufpjqhb7kwlb3vmsvnd: ZUSDLogo,

  // NOTE: Solana token
  umgeqgunb4jadz2egmnx9ny442yzzybems9cptmblaq: USDCLogo,
  '9qccmui4qrvpqwazta7hnw7yu1vyn4sbe1gyit9bb1im': USDTLogo,
  dlhabkcdg6xecrojsp3dri9hki6jeqc4cpsq8r3wiudi: WBTCLogo,
  aczfumqfjgq7ybayrytfikwdxazfbqvzz8bw9oob6jvp: SOLLogo,
}

export const getTokenUrl = (networkId: SupportedNetwork, tokenAddress: string) =>
  networkId === SupportedNetwork.STELLAR || networkId === SupportedNetwork.SOLANA
    ? tokenList[tokenAddress]
    : `https://raw.githubusercontent.com/wombat-exchange/token-icons/master/${networkId}/${tokenAddress}.svg`
