import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExternalLink, ExtraSmallOnly, HideExtraSmall, TYPE } from 'theme'
import { DarkGreyCard } from 'components/Card'
import { getEtherscanLink, getDappLink, POOL_DISPLAY_NAME } from 'utils'
import { AssetData } from '../../state/assets/reducer'
import Loader, { LoadingRows } from 'components/Loader'
import { Link } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import Row, { AutoRow, RowBetween, RowFlat } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import { Label, ClickableText } from '../Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import HoverInlineText from '../HoverInlineText'
import useTheme from 'hooks/useTheme'
import ShareIcon from '../../wombat-assets/icons/share.svg'
import LiquidityButton from '../../wombat-assets/buttons/liquidity-new.svg'
import SwapButton from '../../wombat-assets/buttons/swap-new.svg'
import ArrowBack from '../../wombat-assets/icons/arrow-back-ios.svg'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { SupportedNetwork } from 'constants/networks'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
  background: white;
  border: 3px solid white; 
  opacity: 0.8
  border-radius: 13px;
  padding: 25px 25px;
  font-family: 'Work Sans', sans-serif;
 
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 2.3fr repeat(4, 1fr) 3fr;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 20px 2.7fr repeat(4, 1fr) 4.3fr;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
    & :nth-child(7) {
      grid-column: 1 / 6;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    > *:first-child {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ResponsiveLogo = styled(CurrencyLogo)`
  @media screen and (max-width: 670px) {
    width: 28px;
    height: 28px;
  }
`

const StyledShareIcon = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

const StyledButton = styled.img<{ size: string }>`
  width: ${({ size }) => size};
`

const DataRow = ({ assetData, index }: { assetData: AssetData; index: number }) => {
  const theme = useTheme()
  const [activeNetwork] = useActiveNetworkVersion()
  return (
    <ResponsiveGrid>
      <Label>{index + 1}</Label>
      <Label>
        <RowBetween>
          <LinkWrapper to={'assets/' + assetData.address}>
            <Row>
              <ResponsiveLogo address={assetData.token} />
              <ExtraSmallOnly style={{ marginLeft: '6px' }}>
                <Label ml="8px">{assetData.symbol}</Label>
              </ExtraSmallOnly>
              <HideExtraSmall style={{ marginLeft: '10px' }}>
                <AutoColumn>
                  <HoverInlineText text={assetData.symbol} />
                  <Label fontSize={'14px'} color={theme.wombatGrey}>
                    {assetData.name}
                  </Label>
                </AutoColumn>
              </HideExtraSmall>
            </Row>
          </LinkWrapper>
          <RowFlat>
            {/* TODO: uncomment when ready <YellowBadge fontSize="14px">{feeTierPercent(400)}</YellowBadge> */}
            <ExternalLink
              href={getEtherscanLink(
                assetData.address,
                activeNetwork.id === SupportedNetwork.STELLAR
                  ? 'contract'
                  : activeNetwork.id === SupportedNetwork.SOLANA
                  ? 'address'
                  : 'token',
                activeNetwork
              )}
            >
              <StyledShareIcon src={ShareIcon} size={'24px'}></StyledShareIcon>
            </ExternalLink>
          </RowFlat>
        </RowBetween>
      </Label>
      <Label end={1} fontWeight={400} fontSize="14px">
        {POOL_DISPLAY_NAME[assetData.pool]}
      </Label>
      <Label end={1} fontWeight={400}>
        {formatDollarAmount(assetData.tvlUSD)}
      </Label>
      <Label end={1} fontWeight={400}>
        {formatDollarAmount(assetData.volume7DUSD)}
      </Label>
      <Label end={1} fontWeight={400}>
        {formatDollarAmount(assetData.volume24HUSD)}
      </Label>
      <Label>
        <AutoRow justify={'space-evenly'}>
          <ExternalLink href={getDappLink(assetData.pool, assetData.symbol, 'DEPOSIT', activeNetwork.route)}>
            <StyledButton src={LiquidityButton} size={'128px'}></StyledButton>
          </ExternalLink>
          <ExternalLink href={getDappLink(assetData.pool, assetData.symbol, 'SWAP', activeNetwork.route)}>
            <StyledButton src={SwapButton} size={'128px'}></StyledButton>
          </ExternalLink>
        </AutoRow>
      </Label>
    </ResponsiveGrid>
  )
}

const SORT_FIELD = {
  name: 'name',
  pool: 'pool',
  volume24HUSD: 'volume24HUSD',
  volume7DUSD: 'volume7DUSD',
  tvlUSD: 'tvlUSD',
}

const MAX_ITEMS = 10

export default function AssetTable({
  assetDatas,
  maxItems = MAX_ITEMS,
}: {
  assetDatas: AssetData[] | undefined
  maxItems?: number
}) {
  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    let extraPages = 1
    if (assetDatas) {
      if (assetDatas.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(assetDatas.length / maxItems) + extraPages)
    }
  }, [maxItems, assetDatas])
  const sortedAssets = useMemo(() => {
    return assetDatas
      ? assetDatas
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof AssetData] > b[sortField as keyof AssetData]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [assetDatas, maxItems, page, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!assetDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      {sortedAssets.length > 0 ? (
        <AutoColumn gap="16px">
          <ResponsiveGrid>
            <Label color={theme.text2}>#</Label>
            <ClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.name)}>
              Token Name {arrow(SORT_FIELD.name)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.pool)}>
              Pool {arrow(SORT_FIELD.pool)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
              TVL {arrow(SORT_FIELD.tvlUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volume7DUSD)}>
              Volume 7D {arrow(SORT_FIELD.volume7DUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volume24HUSD)}>
              Volume 24H {arrow(SORT_FIELD.volume24HUSD)}
            </ClickableText>
          </ResponsiveGrid>

          <Break />
          {sortedAssets.map((data, i) => {
            if (data) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} assetData={data} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>
                <img height={14} src={ArrowBack} alt={'back icon'} />
              </Arrow>
            </div>
            <TYPE.body>{page}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false} style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}>
                <img height={14} src={ArrowBack} alt={'next icon'} />
              </Arrow>
            </div>
            <TYPE.body>{' of ' + maxPage}</TYPE.body>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
