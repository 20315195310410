export interface Block {
  number: number
  timestamp: string
}

export enum VolumeWindow {
  daily,
  weekly,
  monthly,
}

export interface ChartDayData {
  date: number
  volumeUSD: number
  tvlUSD: number
}

export interface GenericChartEntry {
  time: string
  value: number
}

export enum TransactionType {
  SWAP,
  MINT,
  BURN,
  REFUND,
}

export type Transaction = {
  type: TransactionType
  refundType?: TransactionType
  hash: string
  timestamp: string
  sentFrom: string
  token0Symbol: string
  token1Symbol: string
  token0Address: string
  token1Address: string
  amountUSD: number
  amountToken0: number
  amountToken1: number
}

/**
 * Formatted type for Candlestick charts
 */
export type PriceChartEntry = {
  time: number // unix timestamp
  open: number
  close: number
  high: number
  low: number
}

/**
 * Formatted type for veWOM Page
 */
export enum VeWomTransactionType {
  STAKE,
  UNSTAKE,
}

export type VeWomTransaction = {
  type: VeWomTransactionType
  sender: string
  tokenForDisplay: string
  hash: string
  timestamp: string
  unlockTimestamp: string
  duration: number
  womAmount: number
  veWomAmount: number
}

export interface VeWomChartDayData {
  date: number
  dayMint: number
  dayBurn: number
  dayTotalSupply: number
  dayLockedWom: number
  dayUnlockedWom: number
}

export type VeWomPieChart = {
  user: string
  womBalance: number
  veWomBalance: number
}
