import { currentTimestamp } from './../../utils/index'
import { updateProtocolData, updateChartData, updateTransactions } from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { ChartDayData, Transaction } from 'types'
import { SupportedNetwork } from 'constants/networks'
import { WomSupplyData } from 'data/protocol/overview'

export interface StakedWomData {
  stakedToday: number
  stakedYesterday: number
  stakedLastWeek: number
  avgLockTimeInSec: number
  currentTotalSupply: number
}

export interface ProtocolData {
  // volume
  volumeUSD: number
  // volumeUSDChange: number

  // in range liquidity
  tvlUSD: number
  // tvlUSDChange: number

  totalFeeCollected: number
  totalBribeRevenue: number

  womSupplyData: WomSupplyData
  stakedWomData: {
    [id in SupportedNetwork]?: StakedWomData
  }
  // fees
  // feesUSD: number
  // feeChange: number

  // transactions
  // txCount: number
  // txCountChange: number
}

export type ProtocolState = {
  [key in SupportedNetwork]: {
    // timestamp for last updated fetch
    readonly lastUpdated: number | undefined
    // overview data
    readonly data: ProtocolData | undefined
    readonly chartData: ChartDayData[] | undefined
    readonly transactions: Transaction[] | undefined
  }
}

function createDefaultInitialState() {
  return {
    data: undefined,
    chartData: undefined,
    transactions: undefined,
    lastUpdated: undefined,
  }
}

export const initialState: ProtocolState = {
  [SupportedNetwork.BNB]: createDefaultInitialState(),
  [SupportedNetwork.ARBITRUM]: createDefaultInitialState(),
  [SupportedNetwork.ETHEREUM]: createDefaultInitialState(),
  [SupportedNetwork.POLYGON]: createDefaultInitialState(),
  [SupportedNetwork.AVALANCHE]: createDefaultInitialState(),
  [SupportedNetwork.BASE]: createDefaultInitialState(),
  [SupportedNetwork.OPTIMISM]: createDefaultInitialState(),
  [SupportedNetwork.SCROLL]: createDefaultInitialState(),
  [SupportedNetwork.STELLAR]: createDefaultInitialState(),
  [SupportedNetwork.SOLANA]: createDefaultInitialState(),
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateProtocolData, (state, { payload: { protocolData, networkId } }) => {
      state[networkId].data = protocolData
      // mark when last updated
      state[networkId].lastUpdated = currentTimestamp()
    })
    .addCase(updateChartData, (state, { payload: { chartData, networkId } }) => {
      state[networkId].chartData = chartData
    })
    .addCase(updateTransactions, (state, { payload: { transactions, networkId } }) => {
      state[networkId].transactions = transactions
    })
)
